import React from 'react';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';

export const OrderExeptionDetail = ({ orderExcetion = {} }) => {
    const { t, i18n } = useTranslation();

    const items = [
        {
            label: t('orders.reference'),
            children: orderExcetion.reference,
        },
        {
            label: t('orders.employee'),
            children: orderExcetion.employeeWhoDelivered
        },
        {
            label: t('orders.deliveryDate'),
            children: orderExcetion.deliveryDate,
        },
        {
            label: t('orders.deliveryLocation'),
            children: <>
                {orderExcetion.deliveryLatitude && orderExcetion.deliveryLongitude && (
                    <span>
                        <a href={`https://www.google.com/maps?q=${orderExcetion.deliveryLatitude},${orderExcetion.deliveryLongitude}`} target="_blank" rel="noopener noreferrer">
                            {orderExcetion.deliveryLatitude},{orderExcetion.deliveryLongitude}
                        </a>
                    </span>
                )}
            </>
        },
        {
            label: t('orders.currentAddress'),
            children: <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    orderExcetion.address + ' ' + orderExcetion.postalCode
                )}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {orderExcetion.address + ' ' + orderExcetion.postalCode}
            </a>,
        },
        {
            label: t('orders.Status'),
            children: orderExcetion.orderStatus,
        },
        {
            label: t('orders.packageRecipient'),
            children: orderExcetion.packageRecipient,
        },
        {
            label: t('orders.deliveryNote'),
            span: { xl: 2, xxl: 2 },
            children: orderExcetion.deliveryNote
        },
        {
            label: t('orders.deliveryEvidenceUrl'),
            children: orderExcetion.deliveryEvidenceUrl,
        }
    ];

    return (
        <Descriptions
            bordered
            column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
            items={items}
        />
    );
};
