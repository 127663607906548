import React, { useEffect, useState } from 'react';
import { Button, Table, Spin, Modal, Tooltip, Form, Input, message, Row, Col, AutoComplete, Radio } from 'antd';
import { API_URL_ORDERS_EXCEPTIONS, API_URL_ORDERS_EXCEPTIONS_ANSWER, API_URL_ORDERS_EXCEPTIONS_CONCLUSION, API_URL_ORDERS_EXCEPTIONS_SELECT, get, patch, post } from '../../../App/Api';
import { useTranslation } from 'react-i18next';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    IssuesCloseOutlined,
    FormOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { Paginator } from '../../../Paginator';
import { InputOrderSearch } from '../SearchReference';
import { FleetFilter } from '../../../Fleet/Filter';
import { OrderExeptionDetail } from '../../OrderExceptions/Details';

export function OrderExceptions({ orderId }) {
    const { t, i18n } = useTranslation();

    const [orderExceptions, setOrderExceptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [referenceSelected, setReferenceSelected] = useState('');
    const [orderExceptionSelected, setOrderExceptionSelected] = useState(null);

    /// Conclusion
    const [isModalConclusionOpen, setIsModalConclusionOpen] = useState(false);
    const [conclusionForm] = Form.useForm();
    const showModalConclusion = (orderException) => {
        setOrderExceptionSelected(orderException);
        setReferenceSelected(orderException?.reference)
        conclusionForm.setFieldValue('orderExceptionId', orderException.orderExceptionId);
        conclusionForm.setFieldValue('answer', orderException.answer);
        conclusionForm.setFieldValue('question', orderException.question);
        setIsModalConclusionOpen(true);
    };
    const hideModalConclusion = () => {
        setIsModalConclusionOpen(false);
    };
    const handleConclusionOk = async () => {
        const values = await conclusionForm.validateFields();
        const url = API_URL_ORDERS_EXCEPTIONS_CONCLUSION.replace('{{orderExceptionId}}', values.orderExceptionId);
        await patch(url, { conclusion: values.conclusion, resolve: values.resolve }).then((success) => {
            message.success(t('order_exception.conclusion.success'));
            setIsModalConclusionOpen(false);
            conclusionForm.resetFields();
            fetchData();
        }).catch((error) => {
            message.error(t('order_exception.conclusion.success') + ": " + error?.response?.data?.message);
        });
    };

    /// Answer
    const [isModalAnswerOpen, setIsModalAnswerOpen] = useState(false);
    const [answerForm] = Form.useForm();
    const showModalAnswer = (orderException) => {
        setOrderExceptionSelected(orderException);
        setReferenceSelected(orderException?.reference);
        answerForm.setFieldValue('orderExceptionId', orderException.orderExceptionId);
        answerForm.setFieldValue('question', orderException.question);
        setIsModalAnswerOpen(true);
    };
    const hideModalAnswer = () => {
        setIsModalAnswerOpen(false);
    };
    const handleAnswerOk = async () => {

        const values = await answerForm.validateFields();
        const url = API_URL_ORDERS_EXCEPTIONS_ANSWER.replace('{{orderExceptionId}}', values.orderExceptionId);
        await patch(url, { answer: values.answer }).then((success) => {
            message.success(t('order_exception.answer.success'));
            setIsModalAnswerOpen(false);
            answerForm.resetFields();
            fetchData();
        }).catch((error) => {
            message.error(t('order_exception.answer.error') + ": " + error?.response?.data?.message);
        });
    };


    /// Question
    const [isModalQuestionOpen, setIsModalQuestionOpen] = useState(false);
    const [questionForm] = Form.useForm();
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState('');

    const fetchSuggestions = async (query) => {
        try {
            const response = await get(API_URL_ORDERS_EXCEPTIONS_SELECT, { q: query });
            const suggestions = response.data.map((item) => ({
                value: item.question,
            }));
            setQuestions(suggestions);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };

    const handleSearchQuestion = (searchText) => {
        if (searchText) {
            fetchSuggestions(searchText);
        } else {
            setQuestions([]);
        }
    };

    const handleChangeQuestion = (data) => {
        setQuestion(data);
    };

    const showModalQuestion = (orderId) => {
        questionForm.setFieldValue('orderId', orderId);
        setIsModalQuestionOpen(true);
    };
    const hideModalQuestion = () => {
        setIsModalQuestionOpen(false);
    };
    const handleQuestionOk = async () => {
        const values = await questionForm.validateFields();
        const url = API_URL_ORDERS_EXCEPTIONS;
        await post(url, { question: values.question, orderId: values.orderId, fleetId: values.fleetId }).then((success) => {
            message.success(t('order_exception.question.success'));
            setIsModalQuestionOpen(false);
            questionForm.resetFields();
            fetchData();
        }).catch((error) => {
            message.error(t('order_exception.question.error') + ": " + error?.response?.data?.message);
        });
    };

    // STATE FOR PETITION
    const [searchInput, setSearchInput] = useState("");
    const [fleetIds, setFleetIds] = useState([]);
    const [perPage, setPerPage] = useState(10);

    const [hasMorePages, setHasMorePages] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const [columns, setColumns] = useState([]);

    const fetchData = async () => {
        setLoading(true);
        try {
            let url = `${API_URL_ORDERS_EXCEPTIONS}`;
            console.log('jaja')
            const response = await get(url, {
                perPage,
                page,
                orderId,
                references: searchInput ? searchInput : null,
                fleetIds,
            });
            setOrderExceptions(response.data);
            setHasMorePages(response.data.length === perPage);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            setIsLoadingMore(false);
        }
    };

    const handleSearch = () => {
        setPage(1);
        fetchData();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handlePreviousPage = () => {
        let newPage = page;
        if (page > 1) {
            newPage--;
            setIsLoadingMore(true);
            setPage(newPage);
        }
    };

    const loadMoreOrders = () => {
        const newPage = page + 1;
        setIsLoadingMore(true);
        setPage(newPage);
    };

    const handleGoToStart = () => {
        if (page > 1) {
            setPage(1);
        }
    };

    useEffect(() => {
        setColumns([
            {
                title: t('orders.Reference'),
                dataIndex: 'reference',
                key: 'reference',
            },
            {
                title: t('fleets.name'),
                dataIndex: 'fleetName',
                key: 'fleetName',
            },
            {
                title: t('status'),
                dataIndex: 'status',
                key: 'status',
                align: 'center',
            },
            {
                title: t('createdByUser'),
                dataIndex: 'userName',
                key: 'userName',
            },
            {
                title: t('canceledByUser'),
                dataIndex: 'canceledUserName',
                key: 'canceledUserName',
            },
            {
                title: t('answeredByUser'),
                dataIndex: 'answerUserName',
                key: 'answerUserName',
            },
            {
                title: t('resolvedByUser'),
                dataIndex: 'resolvedUserName',
                key: 'resolvedUserName',
            },
            {
                title: t('unresolvedByUser'),
                dataIndex: 'unresolvedUserName',
                key: 'unresolvedUserName',
            },
            {
                title: t('orders.Actions'),
                key: 'actions',
                render: (orderException, record) => (
                    renderContent(orderException)
                ),
            },
        ]);
    }, [i18n.language]);

    const renderContent = (orderException) => {

        if (orderException.finishedDate) {
            if (orderException.canceledDate) {
                return <Tooltip placement="topLeft" title={orderException.status}>
                    <CheckCircleOutlined style={{ fontSize: '24px', color: '#66cc66' }} />
                </Tooltip>;
            } else if (orderException.resolve) {
                return <Tooltip placement="topLeft" title={orderException.status}>
                    <CheckCircleOutlined style={{ fontSize: '24px', color: '#66cc66' }} />
                </Tooltip>;
            } else {
                return <Tooltip placement="topLeft" title={orderException.status}>
                    <CloseCircleOutlined style={{ fontSize: '24px', color: '#ff6666' }} />
                </Tooltip>;
            }
        } else if (orderException.answerDate) {
            return <Tooltip placement="topLeft" title={orderException.status}>
                <Button
                    type="primary"
                    icon={<FormOutlined />}
                    onClick={() => showModalConclusion(orderException)}
                ></Button>
            </Tooltip >;
        } else {
            return <Tooltip placement="topLeft" title={orderException.status}>
                <Button
                    type="primary"
                    icon={<IssuesCloseOutlined />}
                    onClick={() => showModalAnswer(orderException)}

                ></Button>
            </Tooltip>;
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, perPage]);

    const filterProps = {
        handleSearch,

        setFleetIds,
        fleetIds,

        setSearchInput,
        searchInput,
    };


    const setFleetIdQuestionForm = (fleetId) => {
        questionForm.setFieldsValue({ fleetId });
    };
    return (
        <>
            {!orderId && (
                <form onKeyDown={handleKeyPress}>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <p style={{ fontSize: '11px', margin: '5px 0' }}>
                                {t('orders.reference')}
                            </p>

                            <InputOrderSearch {...filterProps} />
                        </Col>
                        <Col span={8}>
                            <p style={{ fontSize: '11px', margin: '5px 0' }}>
                                {t('fleets.name')}
                            </p>
                            <FleetFilter {...filterProps} />
                        </Col>
                    </Row>
                    <Row justify="end">
                        <Col>
                            <Button type="primary" onClick={filterProps.handleSearch}>
                                {t('orders.accordion.Search')}
                            </Button>
                        </Col>
                    </Row>
                </form>
            )}
            {loading && <Spin size="large" />}
            {error && (
                <p>
                    {t('orders.Error')}: {error}
                </p>
            )}
            {!loading && (
                <>
                    <div className="orders-footer">
                        {orderId && (
                            <Tooltip placement="topLeft" title={t('order_exception.add')}>
                                <Button
                                    type="primary"
                                    icon={<PlusCircleOutlined />}
                                    onClick={() => showModalQuestion(orderId)}
                                ></Button>
                            </Tooltip >
                        )}
                        <Button
                            onClick={handleGoToStart}
                            disabled={page <= 1 || isLoadingMore}
                        >
                            {t('orders.Ir al inicio')}
                        </Button>
                        <Button
                            onClick={handleGoToStart}
                            disabled={page <= 1 || isLoadingMore}
                        >
                            {t('orders.Ir al inicio')}
                        </Button>
                        <Button
                            onClick={handlePreviousPage}
                            disabled={page <= 1 || isLoadingMore}
                        >
                            {t('previousPage')}
                        </Button>
                        <Button
                            onClick={loadMoreOrders}
                            disabled={!hasMorePages || isLoadingMore}
                            loading={loading}
                        >
                            {t('nextPage')}
                        </Button>
                        <Paginator perPage={perPage} setPerPage={setPerPage} />
                    </div>
                    <div className="orders-table">
                        <Table
                            virtual
                            scroll={{ y: 500, x: 800 }}
                            pagination={false}
                            dataSource={orderExceptions}
                            columns={columns}
                            rowKey="orderExceptionId"
                        />
                    </div>

                    <div className="orders-footer">
                        <Button
                            onClick={handleGoToStart}
                            disabled={page <= 1 || isLoadingMore}
                        >
                            {t('orders.Ir al inicio')}
                        </Button>
                        <Button
                            onClick={handlePreviousPage}
                            disabled={page <= 1 || isLoadingMore}
                        >
                            {t('previousPage')}
                        </Button>
                        <Button
                            onClick={loadMoreOrders}
                            disabled={!hasMorePages || isLoadingMore}
                            loading={loading}
                        >
                            {t('nextPage')}
                        </Button>
                        <Paginator perPage={perPage} setPerPage={setPerPage} />
                    </div>
                </>
            )}
            <Modal
                title={t('order_exception.answer.title') + ":" + referenceSelected}
                open={isModalAnswerOpen}
                onOk={handleAnswerOk}
                onCancel={hideModalAnswer}
                keyboard={false}
                maskClosable={false}
                className="fullscreen-modal"
                width="100%"
                style={{ height: '100vh' }}
            >
                <OrderExeptionDetail orderExcetion={orderExceptionSelected}></OrderExeptionDetail>
                <Form form={answerForm} layout="vertical">
                    <Form.Item
                        name="orderExceptionId"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="question"
                        label={t('order_exception.question.label')}
                    >
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item
                        name="answer"
                        label={t('order_exception.answer.label')}
                        rules={[{ required: true, message: t('order_exception.answer.requerid') }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={t('order_exception.conclusion.title') + ":" + referenceSelected}
                open={isModalConclusionOpen}
                onOk={handleConclusionOk}
                onCancel={hideModalConclusion}
                keyboard={false}
                maskClosable={false}
                className="fullscreen-modal"
                width="100%"
                style={{ height: '100vh' }}
            >
                <OrderExeptionDetail orderExcetion={orderExceptionSelected}></OrderExeptionDetail>
                <Form form={conclusionForm} layout="vertical">
                    <Form.Item
                        name="orderExceptionId"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="question"
                        label={t('order_exception.question.label')}
                    >
                        <Input readOnly />
                    </Form.Item><Form.Item
                        name="answer"
                        label={t('order_exception.answer.label')}
                    >
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item
                        name="resolve"
                        label={t('order_exception.resolve.label')}
                        rules={[{ required: true, message: t('order_exception.resolve.requerid') }]}
                    >
                        <Radio.Group
                            block
                            options={[
                                { label: t('yes'), value: true },
                                { label: t('not'), value: false },
                            ]}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Form.Item>
                    <Form.Item
                        name="conclusion"
                        label={t('order_exception.conclusion.label')}
                        rules={[{ required: true, message: t('order_exception.conclusion.required') }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={t('order_exception.question.title')}
                open={isModalQuestionOpen}
                onOk={handleQuestionOk}
                onCancel={hideModalQuestion}
                keyboard={false}
                maskClosable={false}
                className="fullscreen-modal"
                width="100%"
                style={{ height: '100vh' }}
            >
                <Form form={questionForm} layout="vertical">
                    <Form.Item
                        name="orderId"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="question"
                        label={t('order_exception.question.label')}
                        rules={[{ required: true, message: t('order_exception.question.requerid') }]}
                    >
                        <AutoComplete
                            options={questions}
                            onSearch={handleSearchQuestion}
                            onChange={handleChangeQuestion}
                            value={question}
                            placeholder={t('order_exception.question.label')}
                        >
                            <Input />
                        </AutoComplete>
                    </Form.Item>
                    <Form.Item
                        name="fleetId"
                        label={t('fleets.name')}
                    >
                        <FleetFilter mode={null} setFleetIds={setFleetIdQuestionForm} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
