import React from 'react';
import MainLayout from '../../Layout/MainLayout';
import { OrderExceptions } from '../Orders/OrderExceptions';

export function OrdersExceptions() {
	return (
		<MainLayout>
			<OrderExceptions></OrderExceptions>
		</MainLayout>
	);
}
