import { Button, Card, Tabs, Descriptions, Badge, Tag } from 'antd';

import React, { useEffect, useState } from 'react';
import {
	API_URL_LABEL_PDF,
	API_URL_ORDER_ID,
	API_URL_ORDER_ID_STATUS,
	API_URL_POD_PDF,
	get,
} from '../../../App/Api';
import { PDFViewerComponent } from '../PdfPod';
import {
	UserOutlined,
	MailOutlined,
	PhoneOutlined,
	CheckCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { OrderStatusMap } from '../OrderStatusMap';
import { OrderStatusTracking } from '../OrderStatusTracking';
import { Link, useParams } from 'react-router-dom';
import MainLayout from '../../../Layout/MainLayout';
import { OrderExceptions } from '../OrderExceptions';

export function OrderDetails() {
	const { orderId } = useParams();

	const { t, i18n } = useTranslation();

	const [activeTab, setActiveTab] = useState('orders.details');
	const [trackingDetails, setTrackingDetails] = useState({});
	const [loading, setLoading] = useState(true);
	const [order, setOrder] = useState();

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	useEffect(() => {
		const fetchOrder = async () => {
			try {
				if (orderId) {
					const response = await get(
						API_URL_ORDER_ID_STATUS.replace('{{orderId}}', orderId)
					);
					setTrackingDetails(response.data);

					const orderResponse = await get(
						API_URL_ORDER_ID.replace('{{orderId}}', orderId)
					);
					setOrder(orderResponse.data);
				}
			} catch (error) {
				return false;
			} finally {
				setLoading(false);
			}
		};

		fetchOrder();
	}, [i18n.language]);

	if (loading) {
		<p>{t('orders.orderDetails.Loading order data...')}</p>;
	}

	const colorDays = (days) => {
		if (days > 2) {
			return 'red';
		} else {
			return 'green';
		}
	};

	const address =
		order?.currentAddress +
		' ' +
		order?.postalCode +
		' ' +
		order?.cityName +
		' ' +
		order?.stateName;
	const items = [
		{
			key: '1',
			label: (
				<>
					<Tag color="default">{t('orders.status')}</Tag>
				</>
			),
			children: (
				<Tag icon={<CheckCircleOutlined />} color="success">
					{order?.orderStatusParent}:
					{order?.orderStatus}
				</Tag>
			),
		},
		{
			key: '2',
			label: (
				<>
					<Tag color="default">{t('orders.reference')}</Tag>
				</>
			),
			children: order?.reference,
		},
		{
			key: '3',
			label: (
				<>
					<Tag color="default">{t('clients.name')}</Tag>
				</>
			),
			children: order?.clientName,
		},
		{
			key: '4',
			label: (
				<>
					<Tag icon={<UserOutlined />} color="default">
						{t('recipients.name')}
					</Tag>
				</>
			),
			children: order?.recipientName,
		},

		{
			key: '5',
			label: (
				<>
					<Tag icon={<PhoneOutlined />} color="default">
						{t('recipients.phoneNumber')}
					</Tag>
				</>
			),
			children: order?.recipientPhoneNumber,
		},
		{
			key: '6',
			label: (
				<>
					<Tag icon={<MailOutlined />} color="default">
						{t('recipients.email')}
					</Tag>
				</>
			),
			children: order?.recipientEmail,
		},
		{
			key: '7',
			label: (
				<>
					<Tag color="default">{t('orders.currentAddress')}</Tag>
				</>
			),
			children: (
				<a
					href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
						address
					)}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{address}
				</a>
			),
		},
		{
			key: '8',
			label: (
				<>
					<Tag color="default">{t('orders.postalCode')}</Tag>
				</>
			),
			children: order?.postalCode,
		},
		{
			key: '9',
			label: (
				<>
					<Tag color="default">{t('orders.city')}</Tag>
				</>
			),
			children: order?.cityName + '-' + order?.stateName,
		},
		{
			key: '10',
			label: (
				<>
					<Tag color="default">{t('orders.employee')}</Tag>
				</>
			),
			children: (
				<>
					{order?.employeeName ? order?.employeeName : ''}
					{order?.fleetName ? order?.fleetName : ''}
				</>
			),
		},
		{
			key: '11',
			label: (
				<>
					<Tag color="default">{t('orders.receivedDate')}</Tag>
				</>
			),
			children: order?.receivedDate,
		},
		{
			key: '12',
			label: (
				<>
					<Tag color="default">{t('orders.firstAttemptDate')}</Tag>
				</>
			),
			children: (
				<>
					<Tag color={colorDays(order?.firstAttemptDays)}>
						{order?.firstAttemptDays}
					</Tag>
					{order?.firstAttemptDate}
				</>
			),
		},
		{
			key: '13',
			label: (
				<>
					<Tag color="default">{t('orders.deliveryDate')}</Tag>
				</>
			),
			children: (
				<>
					<Tag color={colorDays(order?.deliveryDays)}>
						{order?.deliveryDays}
					</Tag>
					{order?.deliveryDate}
				</>
			),
		},
		{
			key: 'packageRecipient',
			label: (
				<>
					<Tag color="default">{t('orders.packageRecipient')}</Tag>
				</>
			),
			children: (
				<>
					<Tag color="default">{order?.packageRecipient}</Tag>
				</>
			),
		},
		{
			key: 'recipientDocument',
			label: (
				<>
					<Tag color="default">{t('orders.recipientDocument')}</Tag>
				</>
			),
			children: (
				<>
					<Tag color="default">{order?.recipientDocument}</Tag>
				</>
			),
		},
		{
			key: 'deliveryNote',
			label: (
				<>
					<Tag color="default">{t('orders.deliveryNote')}</Tag>
				</>
			),
			children: (
				<>
					<Tag color="default">{order?.deliveryNote}</Tag>
				</>
			),
		},
	];

	return (
		<MainLayout>
			{order ? (
				<Tabs
					activeKey={activeTab}
					onChange={handleTabChange}
					centered
					className="order-details-tabs"
					type="line"
					tabBarGutter={20}
					items={[
						{
							label: t('orders.details'),
							key: 'orders.details',
							children: (
								<Badge.Ribbon
									status="processing"
									text={order?.orderStatusParent + ":" + order?.orderStatus}
								>
									<Card style={{ width: '100%' }}>
										<Descriptions title={t('orders.details')} items={items} />
									</Card>
								</Badge.Ribbon>
							),
						},
						{
							label: t('order_exception.title'),
							key: 'order_exception',
							children: (
								<Card style={{ width: '100%' }}>
									<OrderExceptions title={t('order_exception.title')} orderId={orderId} />
								</Card>
							),
						},
						{
							label: t('orders.mapEvent'),
							key: 'orders.mapEvent',
							children: (
								<Card style={{ width: '100%' }}>
									<OrderStatusMap detailStatus={trackingDetails} />
								</Card>
							),
						},
						{
							label: t('orders.tracking'),
							key: 'orders.tracking',
							children: (
								<Card style={{ width: '100%' }}>
									<OrderStatusTracking detailStatus={trackingDetails} />
								</Card>
							),
						},
						{
							label: t('orders.shippingLabel'),
							key: 'orders.shippingLabel',
							children: (
								<Card style={{ width: '100%' }}>
									<PDFViewerComponent
										pdfUrl={API_URL_LABEL_PDF.replace(
											'{{orderId}}',
											order?.orderId
										)}
									/>
								</Card>
							),
						},
						{
							label: t('orders.pod'),
							key: 'orders.pod',
							disabled: order?.orderStatusParentCode !== 'DLV',
							children: (
								<Card style={{ width: '100%' }}>
									<PDFViewerComponent
										pdfUrl={API_URL_POD_PDF.replace(
											'{{orderId}}',
											order?.orderId
										)}
									/>
								</Card>
							),
						},
					]}
				></Tabs>
			) : (
				<p>{t('orders.orderDetails.Loading order data...')}</p>
			)}

			<Link to="/orders/">
				<Button type="primary">{t('orders.orderDetails.Back')}</Button>
			</Link>
		</MainLayout>
	);
}
