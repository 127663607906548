import './output.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Detalles } from './Tracking/Detalles/index.jsx';
import { Login } from './Dashboard/Login/index.jsx';
import { Tracking } from './Tracking/index.js';
import { MrwMultiple } from './Dashboard/MrwMultiple/index.jsx';
import { NotFound } from './NotFound/index.js';
import { Orders } from './Dashboard/Orders/index.jsx';
import { OrderDetails } from './Dashboard/Orders/ModalDetails/index.jsx';
import { UserProvider } from './Context/User.jsx';
import { Home } from './Home/index.jsx';
import ProtectedRoute from './Protected/index.jsx';
import { PickingBox } from './Dashboard/PickingBox/index.jsx';
import { Picking } from './Dashboard/Picking/index.jsx';
import { Following } from './Dashboard/Following/index.jsx';
import { LabelMultiple } from './Dashboard/LabelMultiple/index.jsx';
import { MRW } from './Dashboard/Mrw/index.jsx';
import { Fleets } from './Dashboard/Fleets/index.jsx';
import { Employees } from './Dashboard/Employees/index.jsx';
import { Forecast } from './Dashboard/Forecast/index.jsx';
import { RoutesFollowing } from './Dashboard/RoutesFollowing/index.jsx';
import { Manifests } from './Dashboard/Manifests/index.jsx';
import { EmployeeReporting } from './Dashboard/EmployeeReporting/index.jsx';
import { OrdersExceptions } from './Dashboard/OrderExceptions/index.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));

function Autentication() {
	return (
		<UserProvider>
			<React.StrictMode>
				<Router>
					<Routes>
						<Route path="/*" element={<NotFound />} />
						<Route
							path="/"
							element={
								<ProtectedRoute allowedRoles={[]} element={<Orders />} />
							}
						/>
						<Route
							path="/manifests/"
							element={
								<ProtectedRoute allowedRoles={[3]} element={<Manifests />} />
							}
						/>
						<Route
							path="/mrw-multiple/"
							element={
								<ProtectedRoute allowedRoles={[3]} element={<MrwMultiple />} />
							}
						/>
						<Route
							path="/orders/"
							element={
								<ProtectedRoute allowedRoles={[]} element={<Orders />} />
							}
						/>
						<Route
							path="/orders/:orderId"
							element={
								<ProtectedRoute allowedRoles={[]} element={<OrderDetails />} />
							}
						/>
						<Route
							path="/picking/"
							element={
								<ProtectedRoute
									allowedRoles={[2, 3, 5, 6]}
									element={<Picking />}
								/>
							}
						/>
						<Route
							path="/picking_box/"
							element={
								<ProtectedRoute
									allowedRoles={[2, 3, 5, 6]}
									element={<PickingBox />}
								/>
							}
						/>
						<Route
							path="/employees/"
							element={
								<ProtectedRoute allowedRoles={[3]} element={<Employees />} />
							}
						/>
						<Route
							path="/fleets/"
							element={
								<ProtectedRoute allowedRoles={[3]} element={<Fleets />} />
							}
						/>
						<Route
							path="/routes-following/"
							element={
								<ProtectedRoute
									allowedRoles={[2, 3, 5, 6]}
									element={<RoutesFollowing />}
								/>
							}
						/>
						<Route
							path="/following/"
							element={
								<ProtectedRoute
									allowedRoles={[2, 3, 5, 6]}
									element={<Following />}
								/>
							}
						/>
						<Route
							path="/employee-reporting/"
							element={
								<ProtectedRoute
									allowedRoles={[2, 3, 5, 6]}
									element={<EmployeeReporting />}
								/>
							}
						/>
						<Route
							path="/forecast/"
							element={
								<ProtectedRoute
									allowedRoles={[2, 3, 5, 6]}
									element={<Forecast />}
								/>
							}
						/>
						<Route
							path="/mrw/"
							element={<ProtectedRoute allowedRoles={[3]} element={<MRW />} />}
						/>
						<Route
							path="/mrw_multiple/"
							element={
								<ProtectedRoute allowedRoles={[3]} element={<MrwMultiple />} />
							}
						/>
						<Route
							path="/label_multiple/"
							element={
								<ProtectedRoute
									allowedRoles={[2, 3, 5, 6]}
									element={<LabelMultiple />}
								/>
							}
						/>
						<Route
							path="/orders_exceptions/"
							element={
								<ProtectedRoute
									allowedRoles={[2, 3, 5, 6]}
									element={<OrdersExceptions />}
								/>
							}
						/>
						<Route path="/login" element={<Login />} />
						<Route path="/tracking" element={<Tracking />} />
						<Route path="/tracking/:reference" element={<Detalles />} />
					</Routes>
				</Router>
				{/* {loggedIn && <Logout onLogout={handleLogout}>Cerrar sesión</Logout>} */}
			</React.StrictMode>
		</UserProvider>
	);
}

root.render(<Autentication />);
